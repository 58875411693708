<!-- eslint-disable vue/no-v-html -->
<template>
  <v-container
    :id="textPicture?.slug"
    class="custom-fix-margin-top px-0 pt-8 pb-8 pt-md-16 pb-md-16"
    :class="{ 'mt-16': mdAndUp }">
    <v-row align="center">
      <v-col
        cols="12"
        md="6"
        :class="{
          'order-last': textPicture?.picture_position === BoxOrPicturePosition.Left && mdAndUp,
        }">
        <H2Number
          :number="textPicture?.title_has_number ? `${textPicture?.title_number}` : ''"
          :title="`${activeTranslation?.title}`" />

        <div v-if="textPicture?.subtitle" class="custom-subtitle">{{ textPicture?.subtitle }}</div>

        <div
          v-show="activeTranslation?.text_content"
          class="text-cms-content"
          v-html="sanitizeHtml(activeTranslation?.text_content)" />

        <div v-if="tableSpanArray.length > 0 && !mdAndUp" class="text-cms-content">
          <v-row class="mt-8">
            <v-col v-for="(span, index) in tableSpanArray" :key="index" cols="6">
              <div>
                {{ span.textContent }}
              </div>
            </v-col>
          </v-row>
        </div>
        <v-row v-if="textPicture?.show_button && !smAndDown" class="pt-8">
          <v-col cols="12" class="px-0">
            <ButtonPrimary @click="goToDestination(textPicture?.button_destination || '')">
              {{ activeTranslation?.button_label }}
            </ButtonPrimary>
          </v-col>
        </v-row>
      </v-col>

      <v-col
        cols="12"
        md="6"
        class="d-flex"
        :class="{
          'justify-start':
            textPicture?.picture_position === BoxOrPicturePosition.Left && !smAndDown,
          'justify-end': textPicture?.picture_position === BoxOrPicturePosition.Right && !smAndDown,
          'justify-center': smAndDown,
        }">
        <v-img
          rounded="lg"
          :class="{
            'max-width-80': smAndUp,
            'max-height-70': mdAndUp,
            'box-shadow': textPicture?.show_shadow,
            'mt-10': !mdAndUp,
          }"
          :alt="textPicture?.image_alt_text || ''"
          :src="`https://epd.directus.app/assets/${textPicture?.image || ''}?format=webp`"
          :srcset="`https://epd.directus.app/assets/${textPicture?.image || ''}?format=webp 1x, https://epd.directus.app/assets/${textPicture?.image || ''}?format=webp 2x`" />
      </v-col>
    </v-row>

    <v-row v-if="textPicture?.show_button && smAndDown" class="pt-8">
      <v-col cols="12" class="d-flex" :class="{ 'justify-center': smAndDown }">
        <ButtonPrimary @click="goToDestination(textPicture?.button_destination || '')">
          {{ activeTranslation?.button_label }}
        </ButtonPrimary>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import type { LocaleObject } from '@nuxtjs/i18n'
import { useDisplay, useGoTo } from 'vuetify'
import { BoxOrPicturePosition } from '~/types/enums'
import type { TextPicture, TextPictureTranslation } from '~/types/types'
import { sanitizeHtml } from '~/util/sanitizer'

const { locale, locales } = useI18n()
const goTo = useGoTo()
const { smAndDown, mdAndUp, smAndUp } = useDisplay()

const props = defineProps<{
  textPicture: TextPicture
}>()

const { textPicture } = toRefs(props)

const currentLocaleIso = computed(() => {
  return locales.value?.find((l: LocaleObject) => l.code === locale.value)?.language as string
})

const goToDestination = async (target: string | null) => {
  if (
    !target ||
    typeof target !== 'string' ||
    target === null ||
    target.length === 0 ||
    target.split('#')[1]?.length === 0
  ) {
    goTo(0)
  } else if (target.includes('http') || target.includes('www')) {
    await navigateTo(target, {
      open: {
        target: '_blank',
      },
    })
  } else {
    goTo(target, {
      container: 'html',
      duration: 300,
      easing: 'easeInOutCubic',
      offset: 1,
    })
  }
}

const activeTranslation = ref<TextPictureTranslation | null>(
  textPicture.value?.translations?.find(
    (t: TextPictureTranslation) => t.languages_code === currentLocaleIso.value,
  ) || null,
)

if (
  activeTranslation.value?.text_content?.includes('<table') &&
  activeTranslation.value?.text_content?.includes('border="')
) {
  activeTranslation.value.text_content = activeTranslation.value?.text_content.replace(
    /border=".*?"/g,
    '',
  )
}

const tableSpanArray = ref<HTMLSpanElement[]>([])

function formatTable() {
  if (activeTranslation.value?.text_content?.includes('<table') && !mdAndUp) {
    const parser = new DOMParser()

    const doc: Document = parser.parseFromString(
      activeTranslation.value?.text_content ?? '',
      'text/html',
    )

    const spanElements: NodeListOf<HTMLSpanElement> = doc.querySelectorAll('table span')

    tableSpanArray.value = Array.from(spanElements)

    const tableElement = doc.querySelector('table')
    if (tableElement) {
      tableElement.remove()
    }

    activeTranslation.value.text_content = doc.body.innerHTML
  }
}

onMounted(() => {
  formatTable()
  window.addEventListener('resize', formatTable)
})

onUnmounted(() => {
  window.removeEventListener('resize', formatTable)
})
</script>

<style scoped lang="scss">
.custom-fix-margin-top {
  margin-top: -6px !important;
}

:deep(table, tr, td, th) {
  border: none !important;
}
:deep(td) {
  vertical-align: top;
}
:deep(td:not(:last-child)) {
  width: max-content !important;
  padding-right: 12px;
}

:deep(p) {
  padding: 1rem 0;
}

:deep(strong) {
  font-weight: 700;
}

.max-width-80 {
  max-width: 80%;
}

.max-height-70 {
  max-height: 70vh;
}

.box-shadow {
  box-shadow: -20px 20px 25px 0px #4b88ff26;
}
</style>
